<ion-header>
	<ion-toolbar>
		<ion-buttons slot="start">
			<ion-back-button defaultHref = "/"></ion-back-button>
		</ion-buttons>

		<ion-title>
			{{'SYMPTOM_CHECKS_VIEW.DETAILS.TITLE' | translate}}
		</ion-title>

		<ion-buttons slot="end">
			<ion-button>
				<ion-icon
					slot 	= "icon-only"
					[name]	= "'actions' | rccIcon"
				></ion-icon>
			</ion-button>
		</ion-buttons>		

	</ion-toolbar>
</ion-header>


<ion-content 
	[fullscreen] = "true"
	*ngIf = "symptom_check"
>

	<h1 class ="ion-padding">{{symptom_check?.meta.source}}</h1>

	<ion-list>
		<ion-item>
			<ion-label>				
				<p>
					{{'SYMPTOM_CHECKS_VIEW.DETAILS.CREATION_DATE' | translate }}: 
					{{symptom_check.meta.creationDate}}
				</p>								

				<p>
					{{'SYMPTOM_CHECKS_VIEW.DETAILS.SCHEDULE' | translate}}: 
					{{symptom_check.meta.defaultSchedule.toText()}}
				</p>

				<p>
					 
					
				</p>
			</ion-label>

			<ion-buttons slot="end">
				<!-- <ion-button (click) = "shareQR()" slot = "end">
					<ion-icon name="qr-code-outline"></ion-icon>
				</ion-button> -->
			</ion-buttons>

		</ion-item>

		<ion-item [button] = "true" (click) = "symptom_check.togglePause()">

			<ion-label>
				<h3>{{"SYMPTOM_CHECKS_VIEW.DETAILS.STATUS" | translate }}</h3>
				<p>
					<ion-text
						[color]	= "(symptom_check.meta.paused ? 'secondary' : 'primary') "
					> 
						{{'SYMPTOM_CHECKS_VIEW.DETAILS.PAUSED_%1' | fill : (symptom_check.meta.paused ? 'TRUE' : 'FALSE') | translate }}
					</ion-text>
				</p>
			</ion-label>

			<ion-icon 
				[name] 	= "(symptom_check.meta.paused ? 'pause'	: 'active') | rccIcon"
				slot	= "end"
			></ion-icon>

		</ion-item>

	</ion-list>

	<ion-list>

		<ion-list-header>
			<h2>{{'SYMPTOM_CHECKS_VIEW.DETAILS.QUESTIONS' | translate}}:</h2>
		</ion-list-header>

		<rcc-store-item
			*ngFor 				= "let item of symptom_check.questions"			
			[item]				= "item.id | id2Question | async"
			[itemActionRoles]	= "['details']"
		>
		</rcc-store-item>

	</ion-list>
</ion-content>
