<ion-header [translucent]="true">
	<ion-toolbar>
		<ion-buttons slot="start">
			<ion-back-button defaultHref = "/">				
			</ion-back-button>
		</ion-buttons>
		<ion-title>
			{{'SETTINGS.HEADER' | translate}}
		</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content [fullscreen] = "true">
	<ion-list>

		<ion-item *ngFor = "let setting of settings">

			<ion-label>
				{{setting.label | translate}}
			</ion-label>

			<ion-select 
				interface		= "popover"
				[formControl]	= "setting.formControl"
			>
				<ion-select-option 
					*ngFor 	= "let option of setting.options"
					[value]	= "option.value"
				>
					{{ option.label | translate }}
				</ion-select-option>
			</ion-select>


		</ion-item>

	</ion-list>
</ion-content>

