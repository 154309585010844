<ion-header [translucent]="true">

	<ion-toolbar>
		<rcc-common-header slot = "start">
		</rcc-common-header>
		<ion-title>
			{{ "REPORT_META_STORE.HEADER" | transloco }}
		</ion-title>
		<ion-button 
			slot 	= "end"
		>
			<ion-icon>search</ion-icon>
		</ion-button>
	</ion-toolbar>

<!-- 	<ion-toolbar>
		<ion-searchbar [formControl] = "searchControl"></ion-searchbar>
	</ion-toolbar> -->

</ion-header>

<ion-content [fullscreen] = "true">
	
		<rcc-meta-store [metaStore] = "this.symptomCheckMetaStore"></rcc-meta-store>

</ion-content>

