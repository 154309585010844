 <div 
 	*ngIf 	= "query && query.question" 
 	class	= "ion-padding"
 >

		<div class ="values">	
			<div 
				*ngFor  	= "let option of options; index as index"
				[ngClass]	= "{active: option.value === query.answer}"
			>
				<span>{{option.value}}</span>
			</div>
		</div>		

		<ion-range 
			[min] 			= "min" 
			[max] 			= "max" 
			[pin]			= "true"
			[snaps]			= "true"
			[formControl]	= "query.answerControl"
			color			= "primary"
		>	
		</ion-range>

		<div class ="labels">	
			<div 
				*ngFor  	= "let option of options; index as index"
				[ngStyle]	= "getOptionStyle(index, option)" 
			>
				{{option | translate}}
			</div>
		</div>

</div>

