<ion-header >
	<ion-toolbar>
		<rcc-common-header 
			*ngIf = "!filterControl || !showSearch"
			slot  = "start"
		>
		</rcc-common-header>

	


		<ion-title
			*ngIf = "!filterControl || !showSearch"
		>
			{{ metaStore.name | translate }}
		</ion-title>


		<ion-buttons slot = "end">

			<ion-button
				*ngIf 	= "actions?.length > 1" 		
				(click) = "showActions($event)"
			>
				<ion-icon 
					[name] 	= "'actions' | rccIcon" 
					slot 	= "icon-only"
				></ion-icon>				
			</ion-button>

			<ion-button
				*ngIf 	= "actions?.length == 1" 		
				(click) = "actions[0].handler()"
			>
				<ion-icon 
					[name] 	= "actions[0].icon | rccIcon" 
					slot 	= "icon-only"
				></ion-icon>				
			</ion-button>

		</ion-buttons>

		<ion-searchbar
			*ngIf 				= "filterControl && showSearch" 
			[formControl] 		= "filterControl"
		>
		</ion-searchbar>

	</ion-toolbar>

</ion-header>