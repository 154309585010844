<rcc-meta-store-header
	[metaStore] 	= "questionaire"
	[filterControl]	= "filterControl"
>
</rcc-meta-store-header>

<ion-content [fullscreen] = "true">

		<rcc-meta-store 
			[metaStore] 	= "questionaire"
			[filterControl]	= "filterControl"			
		>
		</rcc-meta-store>

</ion-content>
