<rcc-meta-store-header
	[metaStore] 		= "symptomCheckMetaStore"
	[filterControl]		= "filterControl"
>
</rcc-meta-store-header>


<ion-content [fullscreen] = "true">
	
		<rcc-meta-store 
			[metaStore] 	= "symptomCheckMetaStore"
			[filterControl]	= "filterControl"
		></rcc-meta-store>

</ion-content>

