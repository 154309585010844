<ion-menu 
	side		=	"start" 
	[menuId]	=	"this.config.menuId" 
	[contentId] = 	"this.config.contentId"
>

	<ion-header>
		<ion-toolbar>
			<ion-title> {{ 'MAIN_MENU.HEADER' | transloco }} </ion-title>
		</ion-toolbar>
	</ion-header>

	<ion-content>

		<ion-list>
			<ion-menu-toggle>
				<ng-container 
					*ngFor				= "let component of components"
					[ngComponentOutlet] = "component"
				>
				</ng-container>
			</ion-menu-toggle>
		</ion-list>

	</ion-content>

</ion-menu>