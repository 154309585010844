<ion-header [translucent]="true">
	<ion-toolbar>
		<rcc-common-header slot = "start">
		</rcc-common-header>
		<ion-title>
			{{ 'DEV.PAGE' | translate }}	
		</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content [fullscreen] = "true">

	<ion-list>

		<ion-list-header>
			<ion-label>{{ 'DEV.WARNINGS' | translate }}</ion-label>
		</ion-list-header>

		<ion-item *ngFor = "let warning of warnings">
			<ion-icon name ="warning" slot = "start"></ion-icon>
			<ion-label>
				<h3>{{warning.name}}</h3>
				<p>{{warning.note}}</p>
			</ion-label>
		</ion-item>
	</ion-list>

</ion-content>
