<ion-header>
	<ion-toolbar>
		<ion-buttons slot="start">
			<ion-back-button defaultHref = ".."></ion-back-button>
		</ion-buttons>

		<ion-title>
			{{ 'CUSTOM_QUESTION_STORE.EDIT.HEADER_NEW' | translate }}
		</ion-title>

		<ion-buttons slot="end">

			<ion-button 
				color		= "primary"
				fill		= "solid"
				(click) 	= "save()"
				[disabled]	= "allForms.invalid"
			>
				{{'CUSTOM_QUESTION_STORE.EDIT.SAVE' | translate }}
			</ion-button>


		</ion-buttons>

	</ion-toolbar>
</ion-header>


<ion-content [fullscreen] = "true">
	
	<ion-list>

		<ion-item
			*ngIf 	= "allForms.invalid && allForms.touched; else blank"
			color	= "danger"
			lines 	= "none"
		>
			{{'CUSTOM_QUESTION_STORE.EDIT.VALIDATION.%1' | fill: firstError | translate}}
		</ion-item>

		<ng-template #blank>
			<ion-item lines = "none"></ion-item>
		</ng-template>

		<ion-item>			
			<ion-label position = "stacked" >{{ "CUSTOM_QUESTION_STORE.EDIT.TEXT" | translate }}</ion-label>
			<ion-input [formControl] = "question"></ion-input>
		</ion-item>

		<ion-item>
			<ion-label position = "stacked">{{ "CUSTOM_QUESTION_STORE.EDIT.QUESTION_TYPE" | translate }}</ion-label>
			<ion-select 
				[formControl] 	= "questionType"
				interface		= "popover"
			>
				<ion-select-option 
					*ngFor	= "let k of questionTypes "
					[value]	= "k"
				>
					{{ "CUSTOM_QUESTION_STORE.EDIT.QUESTION_TYPES."+k.toUpperCase() | translate }}
				</ion-select-option>
			</ion-select>
		</ion-item>


		<!-- SELECT -->
		<ng-container *ngIf = "questionType.value == 'select'">

				<ion-item 
					[button]	= "true"
					(click) 	= "addSelectOption()"
					lines		= "full"					 
				>
						<ion-label> {{ "CUSTOM_QUESTION_STORE.EDIT.ADD_OPTION" | translate}} </ion-label>
						<ion-icon name = "add-outline"></ion-icon>
				</ion-item>

				<ion-grid class = "ion-no-padding" >

					<ion-row 						
						*ngFor 		= "let option of selectOptions.controls; index as index"						
						class 		= "ion-align-items-end"
						[formGroup]	= "$any(option)"												
					>

							<ion-col size = "3" class = "ion-no-padding">

								<ion-item lines = "full">
									<ion-label position = "stacked">{{ "CUSTOM_QUESTION_STORE.EDIT.OPTIONS.VALUE" | translate }}</ion-label>
									<ion-input formControlName = "value" type = "text"></ion-input>
								</ion-item>

							</ion-col>

							<ion-col size = "7">

								<ion-item lines = "full">
									<ion-label position = "stacked">{{ "CUSTOM_QUESTION_STORE.EDIT.OPTIONS.LABEL" | translate }}</ion-label>
									<ion-input formControlName =  "meaning" type ="text"></ion-input>									
								</ion-item>

							</ion-col>		

							<ion-col size = "2" lines = full>

								<ion-item lines = "full">
									<ion-button 
										(click) = "selectOptions.removeAt(index)"
										fill	= "clear"
										color	= "medium"
										slot	= "start"
									>
										<ion-icon 
											[name]  = "'remove' | rccIcon"
											slot	= "icon-only"
										></ion-icon>	
									</ion-button>
								</ion-item>

							</ion-col>


					</ion-row>
				</ion-grid>

		</ng-container>


		<!-- SCALE discrete -->

		<ng-container *ngIf = "questionType.value == 'scale_discrete'">

				<ion-item 
					[button]= "true"
					(click) = "addScaleOption()"
					lines	= "full"
				>
						<ion-label> {{ "CUSTOM_QUESTION_STORE.EDIT.ADD_OPTION" | translate}} </ion-label>
						<ion-icon name = "add-outline"></ion-icon>
				</ion-item>

				<ion-grid class = "ion-no-padding">

					<ion-row 						
						*ngFor 		= "let option of scaleOptions.controls; index as index"
						class 		= "ion-align-items-end"
						[formGroup]	= "$any(option)"
					>

							<ion-col size = "3" class = "ion-no-padding">

								<ion-item lines = "full">
									<ion-label position = "stacked">{{ "CUSTOM_QUESTION_STORE.EDIT.OPTIONS.VALUE" | translate }}</ion-label>
									<ion-input formControlName = "value" type = "number"></ion-input>
								</ion-item>

							</ion-col>
							<ion-col size = "7">

								<ion-item lines = "full">
									<ion-label position = "stacked">{{ "CUSTOM_QUESTION_STORE.EDIT.OPTIONS.LABEL" | translate }}</ion-label>
									<ion-input formControlName =  "meaning" type ="text"></ion-input>									
								</ion-item>

							</ion-col>		

							<ion-col size = "2" lines = full>

								<ion-item lines = "full">
									<ion-button 
										(click) = "scaleOptions.removeAt(index)"
										fill	= "clear"
										color	= "medium"
									>
										<ion-icon name = "trash-outline"></ion-icon>	
									</ion-button>
								</ion-item>

							</ion-col>		

					</ion-row>
				</ion-grid>

		</ng-container>





		<!-- SCALE continuous -->

		<ng-container *ngIf = "questionType.value == 'scale_continuous'">

			<ion-item>
				<ion-label position = "stacked">
					{{ "CUSTOM_QUESTION_STORE.EDIT.MIN" | translate}}
				</ion-label>
				<ion-input 
					[formControl] 	= "min"
					type			= "number"
				></ion-input>
			</ion-item>

			<ion-item>
				<ion-label position = "stacked">
					{{ "CUSTOM_QUESTION_STORE.EDIT.MAX" | translate}}
				</ion-label>
				<ion-input 
					[formControl] 	= "max"
					type			= "number" 
				></ion-input>
			</ion-item>

			<ion-grid class = "ion-no-padding">
				<ion-row class = "ion-align-items-end">

					<ion-col size = "8" class = "ion-no-padding">
						<ion-item lines = "full">							
							<ion-label>
								{{ "CUSTOM_QUESTION_STORE.EDIT.USE_STEPS" | translate}}:
							</ion-label>
							<ion-toggle [formControl] = "useSteps">							
							</ion-toggle>
						</ion-item>
					</ion-col>

					<ion-col size = "4">
						<ion-item>
							<ion-label position = "stacked">
								{{ "CUSTOM_QUESTION_STORE.EDIT.SIZE" | translate}}:
							</ion-label>
							<ion-input 
								[formControl] 	= "step"
								[disabled] 		= "!useSteps.value"
								type			= "number" 
							></ion-input>
						</ion-item>
					</ion-col>



				</ion-row>
			</ion-grid>

		</ng-container>




		<!-- INPUT -->

		<ng-container *ngIf = "questionType.value == 'input'">

			<ion-grid class = "ion-no-padding">
			
				<ion-row class = "ion-align-items-end">

					<ion-col class	= "ion-no-padding">

						<ion-item >

							<ion-label position = "stacked">
								{{ "CUSTOM_QUESTION_STORE.EDIT.ANSWER_TYPE" | translate }}
							</ion-label>

							<ion-select [formControl] = "answerType" interface = "popover">
								<ion-select-option 
									*ngFor 	= "let type of answerTypes" 
									[value]	= "type"
								>
									{{ "CUSTOM_QUESTION_STORE.EDIT.ANSWER_TYPES."+type.toUpperCase() | translate }}
								</ion-select-option>
							</ion-select>

						</ion-item>

					</ion-col>


					<ion-col 
						*ngIf	= "answerType.value != 'string'"
						size 	= "4"
						class 	= "ion-no-padding" 
					>

						<ion-item>

							<ion-label position = "stacked">
								{{ "CUSTOM_QUESTION_STORE.EDIT.UNIT"| translate }}
							</ion-label>
							<ion-input [formControl] = "unit"></ion-input>					

						</ion-item>

					</ion-col>

				</ion-row>
		
			</ion-grid>


		</ng-container>


	</ion-list>	


	
	
</ion-content>
