<ng-container *ngIf = "mode == 'basic'; else itemTemplate">

	<ion-item-sliding>


		<ng-container [ngTemplateOutlet] = "itemTemplate">
		</ng-container>

		<ion-item-options
			(click)		= "actions()"
		>
			<ion-item-option 
				*ngFor 		= "let action of itemActions"
				color		= "medium"
				[title]		= "action.label | transloco" 
				(click)		= "getHandler(action)()"
			>
				<ion-icon 
					[name] 	= "action.icon | rccIcon"
					slot	= "icon-only"
				></ion-icon>

			</ion-item-option>
		</ion-item-options>

	</ion-item-sliding>

</ng-container>


<ng-template #itemTemplate>

	<ion-item
		(click)		= "actions()"
		[button] 	= "true"
		[color]		= "selected ? 'light' : undefined"
		[detail]	= "mode == 'basic'"
	>
		<ion-icon
			slot	= "start"
			*ngIf	= "itemIcon"
			[name]	= "itemIcon | rccIcon"
			size	= "large"
		></ion-icon>

		<ion-label>		
			<ng-container
				*ngIf						= "itemLabelTemplate"
				[ngTemplateOutlet] 			= "itemLabelTemplate"
				[ngTemplateOutletContext]	= "itemLabelContext"
			>
			</ng-container>
			<ng-content>
			</ng-content>
		</ion-label>
		
		<ion-icon 
			*ngIf 	= "mode == 'complex'" 
			name 	= "'more' | rccIcon"
			slot	= "end"
		></ion-icon>



		<ion-checkbox 
			*ngIf 		= "mode == 'select'" 	
			slot		= "end"
			[checked]	= "selected"
		></ion-checkbox>

	</ion-item>

</ng-template>