<ion-header>
	<ion-toolbar>
		<ion-buttons slot="start">
			<ion-select 
				*ngIf				= "availableDevices.length > 1"				
				[formControl]		= "currentDevice"
				interface			= "popover"
			>
				<ion-select-option 
					*ngFor 	=	"let device of availableDevices" 
					[value] =	"device"
				>
					{{device.label}}
				</ion-select-option>					
			</ion-select>			
		</ion-buttons>


		

		<ion-buttons slot="end">
			<ion-button (click) = "dismiss()">
				<ion-icon name="close"></ion-icon>
			</ion-button>
		</ion-buttons>

	</ion-toolbar>
</ion-header>


<ion-content
	[scrollX]	= "false"
	[scrollY]	= "false"
>	

	<div 
		class = "spin-wrapper"
		*ngIf = "initializing"
	>
		<ion-spinner></ion-spinner>
	</div>	

			
	<zxing-scanner #scanner 
		[enable]	= "scannerEnabled"
		[device] 	= "currentDevice.value"
		[autostart]	= "false"			
	></zxing-scanner>


	<div 
		*ngIf = "cameraNotFound"
		class = "ion-padding"
	>
		{{ 'QR_CODE_SCANNER.MISSING_CAMERA' | transloco }}
	</div>

</ion-content>
