import	{	NgModule					}	from '@angular/core'
import	{	IncomingData				}	from './incoming-data.service'


@NgModule({
	providers: [
		IncomingData
	]
})
export class IncomingDataModule {}
