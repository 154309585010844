<ion-list *ngIf = "query && query.question" [inset] = "true" lines = "none">

	<ion-item lines = "full" *ngIf = "query.question.type != 'boolean'">
		<ion-input			
			[type]      	= "query.question.type == 'string' ? 'text' : 'number'" 
			[formControl] 	= "query.answerControl"
			[debounce] 		= "500"
		></ion-input>  
	</ion-item>
	
	<ion-radio-group 
		*ngIf 			= "query.question.type == 'boolean'"
		[formControl] 	= "query.answerControl"
	>

		<ion-item>
			<ion-label>{{true | translate}}</ion-label>	
			<ion-radio [value] = "true" slot = "end"></ion-radio>
		</ion-item>	

		<ion-item>
			<ion-label>{{false | translate}}</ion-label>	
			<ion-radio [value] = "false" slot = "end"></ion-radio>
		</ion-item>	

	</ion-radio-group>


	<ion-item>
		<ion-note>
			<span *ngFor =" let option of query.question.options; last as isLast">
				{{option.value}} {{isLast ? '' : ','}}
			</span>			
			<span *ngIf ="query.question.min">
				min: {{query.question.min}}
			</span>			
			<span *ngIf ="query.question.max">
				max: {{query.question.max}}
			</span>
			&nbsp;
		</ion-note>
	</ion-item>

	<ion-item>		
		<ion-note 						
			color 	= "danger" 
		>
			{{(!query.answerControl.pristine && query.answerControl.errors?.questionTypeConstraints?.message) || '&nbsp;'}}
		</ion-note>
	</ion-item>

</ion-list>
	