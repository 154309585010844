<ion-list *ngIf = "invalid">
	<ion-item >
		<ion-label>
			<h3>{{ 'QUERIES.QUERY_RUN.INVALID'| translate }}</h3>
			<p>{{ 'QUERIES.QUERY_RUN.CLICK_TO_GO_BACK'| translate}}</p>
		</ion-label>

		<ion-buttons slot="start">
			<ion-back-button defaultHref = ".."></ion-back-button>
		</ion-buttons>

	</ion-item>
</ion-list>

<ion-content *ngIf = "!invalid">



	<div class =" ion-horizontal-padding">
		<rcc-pagination
			[pageHandlers] 	= "pageHandlers"
			[activePage]	= "activePage"
			class			= "ion-padding"
		>
		</rcc-pagination>
	</div>



	<ion-slides 
		[pager]					= "false" 
		[options]				= "slideOpts"
		(ionSlideWillChange)	= "onSlideChange()"
		(ionSlidesDidLoad)		= "onSlideChange()"
	>

	
		<ion-slide 
			*ngFor  = "let query of queries trackBy: trackSlides"			
			class	= "ion-padding"
		>
			<rcc-query-widget 
				*ngIf 	= "query" 
				[query] = "query">
			</rcc-query-widget>			 

		</ion-slide>


		<ion-slide
			class = "ion-padding"
		>
			<div class ="done">
				<ion-text color="dark" class = "ion-padding">
					<h1 *ngIf = "numberOfAnsweredQueries != queries.length">{{'QUERIES.QUERY_RUN.WRAP_UP' | translate}}</h1>
					<h1 *ngIf = "numberOfAnsweredQueries == queries.length">{{'QUERIES.QUERY_RUN.ALL_DONE' | translate}}</h1>
				</ion-text>

				<div>
					<p *ngIf = "numberOfAnsweredQueries != queries.length">	
						{{'QUERIES.QUERY_RUN.ANSWERED' | translate : {count: numberOfAnsweredQueries, total: queries.length} }}	
					</p>

					<p>	
						{{'QUERIES.QUERY_RUN.GO_BACK_TO_EDIT' | translate }}
					</p>
				</div>

				<div class ="spacer"></div>

			</div>

		</ion-slide>	

	</ion-slides>






	<div class = "fab-footer">
		<div>
			<ion-fab-button
				color		= "medium"
				routerLink	= "/"
				size		= "small"
			>
				<ion-icon  [name] = "'close' | rccIcon"></ion-icon>
			</ion-fab-button>	

			<ion-fab-button
				color		= "medium"
				(click)		= "slides.slidePrev()"
				[disabled] 	= "atStart"
				size		= "small"
			>
				<ion-icon  [name] = "'previous' | rccIcon"></ion-icon>
			</ion-fab-button>			

			<ion-fab-button
				color		= "medium"				
				(click)		= "slides.slideNext()"
				[disabled] 	= "atEnd"
				size		= "small"
			>
				<ion-icon [name] = "'next' | rccIcon"></ion-icon>
			</ion-fab-button>			
		</div>

		<div>

			<ion-fab-button
				*ngIf		= "!atEnd"
				color		= "primary"
				(click)		= "checkOff(activeQuery)"
				[disabled]	= "!activeQuery?.complete"
			>
				<ion-icon name = "checkmark"></ion-icon>
			</ion-fab-button>			

			<ion-button
				*ngIf		= "atEnd"
				color		= "primary"
				routerLink	= "/"
			>
				{{'QUERIES.QUERY_RUN.DONE' | translate}}
			</ion-button>


		</div>
	</div>


</ion-content>
