<ion-fab
	vertical 	= "bottom"
	horizontal	= "end"
	slot		= "fixed"
>
	<ion-fab-button 
		color	= "light"
		(click) = "toggleMenu()"

	>

		<ion-icon 
			[name] 	= "'menu' | rccIcon"
		></ion-icon>

	</ion-fab-button>	
</ion-fab>


<ion-content 
	[fullscreen] 	= "true"
>
	

	<div class = "spacer"></div>

	<h1 class ="ion-text-center">{{ 'APP_NAME' | translate }}</h1>

	<ion-list>
		
		<ion-item 
			[button]	= "true"
			routerLink 	= "/due-questions" 
		>

			<ion-fab-button slot = "start">
				<ion-icon
					[name] = "'notification' | rccIcon"
				></ion-icon>
			</ion-fab-button>

			<ion-label>
				<h3>{{ 'DUE_QUESTIONS.MENU_ENTRY' | translate}}</h3>
				<p class = "ion-text-wrap"> {{'DUE_QUESTIONS.DESCRIPTION' | translate : {count: (notificationService.notification$ | async) } }} </p>
			</ion-label>

		</ion-item>

		<ion-item 
			[button]	= "true"
			(click)		= "qrCodeService.scanAndAnnounce()"
		>
			<ion-fab-button slot = "start">
				<ion-icon
					[name] = "'scan' | rccIcon"
				></ion-icon>
			</ion-fab-button>


			<ion-label>
				<h3>{{ 'QRCODE.SCAN' | translate}}</h3>
				<p class="ion-text-wrap">{{'QRCODE.DESCRIPTION' | translate}} </p>
			</ion-label>

		</ion-item>

		<ion-item 
			[button]	= "true"
			routerLink 	= "/charts/B" 
		>

			<ion-fab-button slot = "start">
				<ion-icon
					[name] = "'chart' | rccIcon"
				></ion-icon>
			</ion-fab-button>

			<ion-label>
				<h3>{{ 'CHARTS.MENU_ENTRY' | translate}}</h3>
				<p class="ion-text-wrap">{{'CHARTS.DESCRIPTION' | translate}} </p>
			</ion-label>

		</ion-item>

	</ion-list>

	<div class = "spacer"></div>

</ion-content>
