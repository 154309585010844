<ion-header>
	<ion-toolbar>
		<rcc-common-header slot = "start">
		</rcc-common-header>
		<ion-title>
			{{ "QUERIES.HEADER" | translate }}
		</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content class ="ion-padding">
	<rcc-query-widget 
		*ngIf 			= "query" 
		[query] 		= "query"
		[submitButton] 	= "true"
	>
	</rcc-query-widget>
</ion-content>

